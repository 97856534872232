import { deleteService, setServicesListFailed, setServicesListSuccess } from './services.actions';
import { Service } from '@models/service/service-admin.interface';
import { createReducer, on } from '@ngrx/store';

export const servicesFeatureKey = 'services';

export type ServicesState = {
  services: Service[];
  length: number;
};

const initialState: ServicesState = {
  services: [],
  length: 0,
};

export const servicesReducer = createReducer(
  initialState,
  on(deleteService, (state, { id }) => {
    const services = [...state.services];
    const length = state.length;
    const index = services.findIndex((service) => service.id === id);
    if (index > -1) {
      services.splice(index, 1);
      return { services, length: length - 1 };
    }

    return state;
  }),
  on(setServicesListSuccess, (_state, { services, length }) => {
    return { services, length };
  }),
  on(setServicesListFailed, (_state) => {
    return { services: [], length: 0 };
  })
);
