import { Service } from '@models/service/service-admin.interface';
import { createAction, props } from '@ngrx/store';

export const loadServicesList = createAction(
  '[Services] Load All',
  props<{ search?: string; category?: string; hitsPerPage?: number; page?: number }>()
);
export const deleteService = createAction('[Services] Delete', props<{ id: string }>());
export const setServicesListSuccess = createAction(
  '[Services] Set success',
  props<{ services: Service[]; length: number }>()
);
export const setServicesListFailed = createAction('[Services] Set failed');
